<template>
  <div class="company">
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in company.store_slide" :key="index">
        <img v-lazy="image" height="300" width="100%" />
      </van-swipe-item>
    </van-swipe>
    <div class="item company-item">
      <div class="logo" style="width:20%">
        <img :src="company.store_logo" />
      </div>
      <div class="company-item-name" style="width:53%;margin-left:10px">
        <div class="name">{{ company.store_name }}</div>
        <div class="company-item-buttom" style=" display: flex;">
          <div style="background-color:#ff0000;color:#ffffff;">认证商家</div>
          <div style="background-color:rgb(12 187 47);color:#ffffff;">{{ company.sc_name }}</div>
        </div>
      </div>
      <div class="collect" style="width:20%">
        <div class="clear"></div>
        <i class="iconfont icon-xing" :style="xingStyle" @click="followStore(company.is_store_collect)"> </i>
        <span class="collect-num">{{ company.store_collect }}</span>
        <span>粉丝</span>
      </div>
      <div class="clear"></div>
    </div>
    <div class="location">
      <van-icon name="location-o" color="#ee0a24" size="14" />
      {{ company.address2 }}
    </div>

    <van-tabs v-model="active">
      <van-tab title="简介">
        <div class="info">{{ company.info }}</div>
        <div class="info">
          <div v-for="(image, index) in company.store_infoimg" :key="index">
            <img alt="详情图 " :src="image" :style="infoImg" />
          </div>
        </div>
      </van-tab>
      <van-tab title="笔记">
        <van-list v-model="loading" :finished="finished" finished-text=" " loading-text=" " @load="onLoad">
          <div
            class="list-item"
            style="width:96%"
            v-for="index in homelist.length"
            :key="index"
            @click="btndetail(homelist[index - 1].id)"
          >
            <div class="item-image">
              <img v-lazy="homelist[index - 1].img[0]" alt="加载错误" /><!-- 推荐图片/视频 -->
            </div>
            <div class="item-body">
              <div class="item-title">{{ homelist[index - 1].title }}</div>
              <div class="item-list-user">
                <img
                  :src="homelist[index - 1].fbr ? homelist[index - 1].fbr.img : homelist[index - 1].head_pic"
                  class="item-list-user-img"
                /><!-- 用户头像 -->
                <span class="item-name">{{
                  homelist[index - 1].fbr ? homelist[index - 1].fbr.name : homelist[index - 1].nickname
                }}</span
                ><!-- 用户昵称 -->

                <div class="item-fabs">
                  <span class="iconfont icon-heart heart"></span>
                  <span style="margin-top:2px">{{ homelist[index - 1].zan }}</span
                  ><!-- 点赞次数 -->
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <div style="height: 100px"></div>
  </div>
</template>

<script>
import { getType, getTradeByStore } from '@/network/home'
import { getCompanyDetails } from '@/network/company'
import { followUser } from 'network/follow'
export default {
  name: 'detail',
  data() {
    return {
      baseURL: process.env.VUE_APP_IMG_PATH,
      homelist: [],
      pageIndex: 0,
      totalPages: 1,

      loading: false,
      finished: false,

      xingStyle: '',
      companyId: '',
      active: 0,
      company: {},
      infoImg: '',
      typeMap: null
    }
  },
  created() {
    this.companyId = this.$route.params.id
    this.init()
    this.queryTradeList()
    this.infoImg = 'width:' + (document.documentElement.clientWidth - 20) + 'px;'
  },
  methods: {
    onLoad() {
      var _self = this
      getTradeByStore(_self.companyId, _self.pageIndex).then((res) => {
        if (res.data && res.data.status == 1) {
          const result = res.data.result
          result.trade_lis.forEach((item) => {
            item.img = item.img.indexOf('http') >= 0 ? item.img : this.baseURL + item.img
          })
          _self.homelist = _self.homelist.concat(result.trade_list)
          if (result.trade_list.length > 0) {
            _self.pageIndex += 1
            _self.loading = false
            _self.finished = false
          } else {
            _self.loading = true
            _self.finished = true
          }
        }
      })
    },
    btndetail(cid) {
      this.$router.push('/homedetail/' + cid)
    },
    async init() {
      const _self = this
      if (_self.typeMap == null) {
        _self.typeMap = new Map()
        await getType().then((res) => {
          res.data.result.forEach((item) => {
            this.typeMap.set(item.id, item.name)
          })
        })
      }
      getCompanyDetails(_self.companyId).then((res) => {
        if (res.data.status == 1 && res.data.result.info) {
          let companyInfo = res.data.result.info
          companyInfo.sc_name = _self.typeMap.get(companyInfo.sc_id)
          companyInfo.store_logo = _self.baseURL + companyInfo.store_logo
          companyInfo.weixin_img = _self.baseURL + companyInfo.weixin_img
          if (companyInfo.store_slide) {
            companyInfo.store_slide = companyInfo.store_slide.map((item) => {
              return _self.baseURL + item
            })
          }
          if (companyInfo.store_infoimg) {
            companyInfo.store_infoimg = companyInfo.store_infoimg.map((item) => {
              return _self.baseURL + item
            })
          }
          if (companyInfo.is_store_collect == 1) {
            _self.xingStyle = 'color:#ea9719;'
          }
          _self.company = companyInfo
        }
      })
    },
    queryTradeList() {
      var _self = this
      var pageIndex = _self.pageIndex
      if (pageIndex < _self.totalPages) {
        getTradeByStore(_self.companyId, _self.pageIndex).then((res) => {
          _self.homelist = _self.homelist.concat(res.data.result.trade_list)
          _self.totalPages = res.data.result.page.totalPages
          _self.pageIndex = res.data.result.page.nowPage
        })
      }
    },
    followStore(isStoreCollect) {
      followUser(this.companyId, isStoreCollect).then((res) => {
        if (res.data.status == 1) {
          if (isStoreCollect == 1) {
            this.company.is_store_collect = 0
            this.company.store_collect -= 1
            this.xingStyle = ''
            this.$message('取消关注成功')
          } else {
            this.company.is_store_collect = 1
            this.company.store_collect += 1
            this.xingStyle = 'color:#fe2b54;'
            this.$message('关注成功')
          }
        } else {
          this.$message('网络错误!')
        }
      })
    }
  }
}
</script>
<style scoped>
.company {
  background-color: #efeff4;
}
.company .info {
  padding: 5px 10px;
  line-height: 20px;
  font-size: 14px;
  color: #8f8f94;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  background-color: #fff;
}

.company .item {
  margin-top: 5px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}
.company .location {
  margin-bottom: 10px;
  padding: 16px 10px;
  background-color: #fff;
  font-size: 13px;
  color: #999;
}
.company .item .logo,
.company .item .content,
.company .item .content .name {
  float: left;
}
.company .item .clear {
  clear: both;
}
.company .item .logo img {
  width: 60px;
  height: 60px;
}
.company .item .content {
  line-height: 24px;
  margin-left: 6px;
  font-size: 14px;
}
.company .item .content .addr {
  color: #555;
}
.company .item .collect {
  margin-top: 36px;
  font-size: 9px;
  color: #888;
  float: right;
}
.company .item .pic img {
  width: 32px;
  margin-bottom: 14px;
  text-align: right;
  color: #888;
}
.company .item .collect img {
  vertical-align: text-top;
  width: 12px;
  color: #888;
}
.company .item .collect .collect-num {
  color: #ff0000;
  margin: 0 2px 0 5px;
}
.home-list-item {
  margin-left: 1px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-color: rgba(230,230,230,.8); */
}

.list-item {
  width: 47.3%;
  margin: 4px 5px;
  background-color: #fff;
  border-radius: 8px;
}
.item-image img {
  width: 100%;
  border-radius: 8px;
}
.item-title {
  width: 100%;
  padding: 5px 0;
  margin: 0 5px;
  font-size: 15px;
}
.item-list-user img {
  width: 20px;
  border-radius: 50%;
}

.item-list-user {
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.item-name {
  font-size: 8px;
  color: rgb(120, 120, 120);
  flex: 1;
  margin-left: 6px;
}
.item-fabs {
  font-size: 12px;
  color: rgb(120, 120, 120);
  display: flex;
}
.item-fabs .icon-heart {
  margin-right: 5px;
}
.heart {
  color: rgb(120, 120, 120);
}
.headred {
  background-color: red;
}
.company-item {
  width: 100%;
  font-size: 14px;
  display: flex;
  margin: 15px 0;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.3);
}
.company-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}
.company-item .company-item-name {
  margin-top: 5px;
  width: 58%;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.company-item-name span {
  margin-bottom: 6px;
  font-size: 14px;
}
.company-item-name a {
  font-size: 12px;
}
.company-item-buttom {
  width: 150px;
}
.company-item-buttom div {
  margin: 5px 5px 5px 0px;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
</style>
